import { React, useState } from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Button from 'react-bootstrap/Button';
import "./Show1.css"
import PriceBox from './PriceBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ShowDefault(props) {


    const handlePage = (e) => {
        console.log(e.target);
        console.log(e.target.id);
        console.log(e.target.parentElement.id);
        if (e.target.id === "") props.setPrice(e.target.parentElement.id);
        else props.setPrice(e.target.id);
        props.fct("page1");
    }



    return (
        <>
        <div className="header" style={{marginBottom:"10px"}}>
            <div className="header-left">
                <ArrowBackIcon ></ArrowBackIcon>
                <p style={{color:"white", fontWeight:"600"}}>Price per charge</p>
            </div>
        </div>
            <div className="page-padding show1">
                <div style={{marginBottom:"25px"}}>
                    <p>CHF <span className="span-color">0.50</span>/kwH</p>
                    <p className="small-text">8.1% VAT included</p>
                </div>

                <div style={{marginBottom:"25px"}}>
                    <p className="add-weight">Parking fee</p>
                    <p style={{fontSize:"1rem"}}>CHF <span className="span-color">1.60</span>/h while charging and CHF 1.60/h after charging</p>
                </div>
                <div style={{marginBottom:"25px"}}>
                    <p className="add-weight">Maximum charging amount</p>
                    <p style={{fontSize: "1rem", color:"black"}}>Authorize the payment of the chosen amount. You will only pay for what you consume.</p>
                    <br></br>
                    <p style={{fontSize: "1rem", color:"black"}}>By clicking on an amount, you accept the standard </p>
                    <a href="" style={{ color: "#38b6ab", textDecoration: "none" }} target="_blank">terms and conditions.</a>
                </div>
                <div style={{display:"flex", alignItems:"center", gap:"15px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><rect width="14" height="9" x="1" y="7" fill="rgb(57, 182, 32)" rx="2" /><path stroke="rgb(57, 182, 32)" strokeWidth="2" d="M5 5a3 3 0 0 1 6 0v3H5V5Z" /></svg>
                    <p style={{ color: "rgb(57, 182, 32)", fontWeight:"700", fontSize:"1rem" }}>100% secure payment with Saferpay</p>
                </div>
                <div>
                    <PriceBox id = "10" text="Max CHF 10 - around 17.46 kWh" hp = {handlePage}></PriceBox>
                    <PriceBox id = "20" text="Max CHF 20 - around 34.92 kWh" hp = {handlePage}></PriceBox>
                    <PriceBox id = "30" text="Max CHF 30 - around 52.38 kWh" hp = {handlePage}></PriceBox>
                    <PriceBox id = "100" text="Max CHF 100 - around 174.60 kWh" hp = {handlePage}></PriceBox>
                    <div style={{ textAlign: "center", marginTop:"50px" }}>
                        <h2 style={{ fontSize: "1.25rem", fontWeight: "600", marginBottom: "5px" }}>Subscribe and save on kWh</h2>
                        <p style={{ fontWeight: "600" }}>Discover our premium subscriptions</p>
                    </div>
                </div>
            </div>
        </>
    )
}