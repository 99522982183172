import '../App.css';
import { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/Modal';
import "./Park.css"
import ShowDefault from '../Components/ShowDefault';
import Show1 from '../Components/Show1';
import Payment2 from './Payment2';



function Park(props) {
  const [title, setTitle] = useState("Park");
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(true);
  const [mode, setMode] = useState(["item0"]);
  const [page, setPage] = useState("page0");
  const [lName, setLName] = useState("");
  const [fName, setFName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [dataExp, setDataExp] = useState("");
  const[price, setPrice] = useState("");
  const handleMode = (m) => {
    console.log(m);
    if (m) {
      if (m !== 'item1')
      setPage(m);
      else setMode(m);

    }
  }

  const handleBack = () => {
    if (page === "page1") { setPage("page0"); }
    if (page === "page2") { setPage("page1"); }
    if (page === "page3") { setPage("page0"); }
  }

  function DisplayBody(props) {
    const handleM = (m) => {
      if (m)
        props.hm(m);
    }
    switch (mode) {
      case 'item0':
        return (<ShowDefault fullParam={props.fullParam} fct={handleM} />)
      case 'item1':
        return (<Show1 setPrice={props.setPrice} fct={handleM} />)
      default:
        return (<ShowDefault fullParam={props.fullParam} fct={handleM} />)
    }
  }



  return (
    <>
      <Modal
        show={show}
        scrollable={true}
        onHide={handleClose}
        dialogClassName="modal-90w"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
        style={{ backgroundColor: "rgb(0, 2, 50)" }}>
        <Modal.Body
            ref={(el) => {
              if (el) {
                el.style.setProperty('padding', "0", 'important')}
            }}
        >
        {page === "page0" ? <>
          <DisplayBody setPrice={setPrice} fullParam={props.fullParam} hm={handleMode} mode={mode} />
          </> : <Payment2 price={price} lName={lName} fName={fName} hm={handleMode} setCardNumber={setCardNumber} setDataExp={setDataExp} handleBack={handleBack} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Park;