import React from "react"
import "./PayComponent.css"


export default function Roaming(props) {
    return (

        <div className="pay-component" onClick={props.handlePage} style={{cursor:"pointer"}}>
            <div className="left">
                <div>
                    <img src="images/roaming.svg" className="credit-card-img"></img>
                </div>
                <div>
                    <p style={{fontWeight:"600", fontSize:"1rem"}}>Roaming</p>
                </div>
            </div>
            <div className="right">
                <div>
                    <img src="/images/next.svg"  style={{filter: "brightness(0.4)"}}></img>
                </div>
            </div>
        </div>
    )
}