import React from "react"
import "./CardText.css"
export default function CardMessage() {
    return (
        <div className="message-box">
            <div>
                <p style={{color:"inherit", fontWeight:"600"}}>Choose your payment method</p>
                {/* <p style={{ fontWeight: "300", color:"inherit" }}>Parking Indigo Lausanne Mon-Repos</p> */}
            </div>
        </div>
    )
}