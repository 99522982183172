import { React, useState } from 'react'
import PriceLine from './PriceLine';
import "./ShowDefault.css"
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import EvType from './EvType';
import Jette from './Jette';
import Card from 'react-bootstrap/Card';
import CardMessage from "./CardMessage";
import PayComponent from './PayComponent';
import Roaming from './Roaming';


export default function ShowDefault(props) {
    const [show, setShow] = useState({ show1: false, show2: false, show3: false, show4: false, show5: false, show6: false });
    const updateShow = (innerShow) => {
        setShow({ ...innerShow });
    }

    const handlePage = () => {
        props.fct("item1");
    }



    return (
        <>
            <Card style={{ width: '100%', maxHeight: "50%" }}>
                <Card.Img style={{ maxHeight: "200px" }} variant="top" src="/images/park_photo.jpeg" />
                <Card.Body style={{ backgroundColor: "#5fb9ad" }}>
                    <CardMessage></CardMessage>
                </Card.Body>
            </Card>
            <div className="page-padding">
                <PayComponent handlePage={handlePage}></PayComponent>
                <Roaming handlePage={handlePage}></Roaming>

                <div className="subscribe-section">
                    <div className="subscribe-info">
                        <div style={{ textAlign: "center" }}>
                            <h2 style={{ fontSize: "1.25rem", fontWeight: "600", marginBottom: "5px" }}>Subscribe and save on kWh</h2>
                            <p style={{ fontWeight: "600" }}>Discover our premium subscriptions</p>
                        </div>
                        <button className="button-cta">Learn more</button>
                    </div>
                </div>
            </div>
        </>

    )
}   