import React from "react"
import "./PayComponent.css"


export default function PayComponent(props) {
    return (
        <div className="pay-component" onClick={props.handlePage} style={{ marginBottom: "20px", cursor:"pointer" }}>
            <div className="left">
                <div>
                    <img src="images/credit-card.png" className="credit-card-img"></img>
                </div>
                <div>
                    <div>
                        <p style={{fontWeight:"600", fontSize:"1rem"}}>Digital Payment</p>
                        <div className="pay-method-group">
                            <img src="./images/visa.svg" className="pay-method" />
                            <img src="./images/mc.svg" className="pay-method" />
                            <img src="./images/apple_pay.svg" className="pay-method" />
                            <img src="./images/twint.svg" className="pay-method" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div>
                    <img src="/images/next.svg" style={{filter: "brightness(0.4)"}}></img>
                </div>
            </div>
        </div>
    )
}